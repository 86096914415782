const RollingStocks = {
    A: [
        {
            value: "Собственный",
            label: "Собственный (СПС)",
        },
        {
            value: "Инвентарный",
            label: "Инвентарный (МПС)",
        },
    ],

    B: [
        {
            value: 'Платформа',
            label: 'Платформа',
        },
        {
            value: 'Вагон',
            label: 'Вагон',
        },
        {
            value: 'Полувагон',
            label: 'Полувагон',
        },
        {
            value: 'Контейнер',
            label: 'Контейнер',
        },
        {
            value: 'Крытый вагон',
            label: 'Крытый вагон',
        },
        {
            value: 'Цистерна',
            label: 'Цистерна',
        },
        {
            value: 'Фитинговая платформа',
            label: 'Фитинговая платформа',
        },
        {
            value: 'СПС платформа',
            label: 'СПС платформа',
        },
        {
            value: 'МПС Контейнер',
            label: 'МПС Контейнер',
        },
        {
            value: "Транспортёр",
            label: "Транспортёр",
          }
    ]
}

export default RollingStocks
