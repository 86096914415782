<template>
    <div class="col-12 mb-3" :class="classes === undefined ? 'col-lg-3' : 'col-lg-' + classes[0]">
        <label for="departure_country" class="form-label" :class="errorDepColor">
            Departure Country <span class="text-danger">*</span>
        </label>
        <Multiselect class="form-control-sm" v-model="departure.selected" :searchable="true" :hideSelected="true"
            :options="departure.options" placeholder="Departure country" @search-change="getOptions($event, 'departure')"
            :object="true" @input="onOptionSelect($event, 'departure')" />
    </div>

    <div class="col-12 mb-3" :class="classes === undefined ? 'col-lg-3' : 'col-lg-' + classes[1]">
        <label for="destination_country" class="form-label" :class="errorDesColor">
            Destination Country <span class="text-danger">*</span>
        </label>
        <Multiselect class="form-control" v-model="destination.selected" :searchable="true" :hideSelected="true"
            :options="destination.options" placeholder="Destination country" @search-change="getOptions($event, 'destination')" :object="true"
            @input="onOptionSelect($event, 'destination')"  />
    </div>
</template>
  
<script>
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";
import axios from "axios"

export default {
    name: 'SelectCountries',
    emits: ['onSelect'],
    data() {
        return {
            destination: {
                selected: null,
                options: []
            },
            departure: {
                selected: null,
                options: []
            }
        }
    },
    props: {
        ratio: Array,
        current_departure: {
            type: Object,
            default: () => {
            },
            required: false
        },
        current_destination: {
            type: Object,
            default: () => {
            },
            required: false
        },
    },
    methods: {
        async getOptions(query, option_type) {
            if (query.length <= 1) return;

            if (option_type === 'departure') {
                let response = await axios.get('/core/countries/', {
                    params: {
                        search: query
                    }
                })
                let countries = response.data.results
                this.departure.selected = null
                this.departure.options = countries.map((country) => {
                    return {
                        value: parseInt(country.id),
                        label: country.name,
                    }
                })

            } else if (option_type === 'destination') {
                let response = await axios.get('/core/countries/', {
                    params: {
                        search: query
                    }
                })
                let countries = response.data.results
                this.destination.selected = null
                this.destination.options = countries.map((country) => {
                    return {
                        value: parseInt(country.id),
                        label: country.name,
                    }
                })
            }

        },
        onOptionSelect(event, option_type) {
            this.$emit('onSelect', {
                option: option_type,
                value: event === null ? null : event
            })
        },
    },
    computed: {
        classes: {
            get() {
                return this.ratio
            }
        },
        errorDepColor: {
            get() {
                return this.departure.selected === null && this.current_departure ? 'text-danger' : ''
            }
        },
        errorDesColor: {
            get() {
                return this.destination.selected === null && this.current_destination ? 'text-danger' : ''
            }
        }
    },
    components: {
        Multiselect
    },
    mounted() {
        if (this.current_departure !== undefined && this.current_departure !== null) {
            this.departure.options = [{
                value: this.current_departure.id,
                label: this.current_departure.name,
            }] // set options
            this.departure.selected = this.departure.options[0] // set the selected option
        }
        if (this.current_destination !== undefined && this.current_destination !== null) {
            this.destination.options = [{
                value: this.current_destination.id,
                label: this.current_destination.name,
            }] // set options
            this.destination.selected = this.destination.options[0] // set the selected option
        }
    },
    watch: {
        current_departure: {
            handler(newValue) {
                if (newValue) {
                    this.departure.options = [{
                        value: newValue.id,
                        label: newValue.name,
                    }] // set options
                    this.departure.selected = this.departure.options[0] // set the selected option
                } else {
                    this.departure.selected = null
                }
            },
            deep: true
        },
        current_destination: {
            handler(newValue) {
                if (newValue) {
                    this.destination.options = [{
                        value: newValue.id,
                        label: newValue.name,
                    }] // set options
                    this.destination.selected = this.destination.options[0] // set the selected option
                } else {
                    this.destination.selected = null
                }
            },
            deep: true
        }
    }
}

</script>
  
<style scoped></style>